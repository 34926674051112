<!-- 首页 -->
<template>
	<dv-full-screen-container>
		<div class="home">
			<!-- 头部 -->
			<div class="header">
				<header>南山镇农业大数据服务平台</header>
				<div style="display: flex;position: relative;top: -40px;">
					<dv-decoration-10 style="width:35%;height:5px;" />
					<dv-decoration-5 style="width:30%;height:40px;position: relative;top: -5px;" />
					<dv-decoration-10 style="width:35%;height:5px;" />
				</div>
				<div style="text-align: center;top: -45px;position: relative;">服务热线：400-800-7973</div>
			</div>
			<!-- 内容部分 -->
			<div class="mainCenter">
				<!-- 上部分 -->
				<div class="topHome">
					<div class="topleft">
						<!-- 天气 -->
						<div class="topleft_top item">
							<div class="title">实时天气预报</div>
							<Wether :weatherInfo="weatherInfo"></Wether>
							<div class="footer_item"></div>
						</div>
						<!-- 农产品占比 -->
						<div class="topleft_bottom item">
							<Pie :scaleData="scaleData"></Pie>
							<div class="footer_item"></div>
						</div>
					</div>
					<!-- 地图 -->
					<div class="topcenter item">
						<div class="headerNan">南山镇全景图</div>
						<MapLine></MapLine>
						<div class="databoxinfo">
							<dv-border-box-13>
								<div class="dv-border-box">
									<div style="display: flex;align-items: center;">
										<img src="../static/img/people.png" style="width: 22px;height: 20px;margin-right: 5px;">
										<div>全镇人口</div>
									</div>
									<div class="count">
										<countTo :endVal='townshipInfo.population' :duration='1000'></countTo>
									</div>
								</div>
							</dv-border-box-13>
							<dv-border-box-13>
								<div class="dv-border-box">
									<div style="display: flex;align-items: center;">
										<img src="../static/img/plant.png" style="width: 24px;height: 24px;margin-right: 5px;">
										<div>种植户数量</div>
									</div>
									<div class="count">
										<countTo :endVal='townshipInfo.planter' :duration='1000'></countTo>
									</div>
								</div>
							</dv-border-box-13>
							<!-- 耕种 -->
							<div class="areaBox">
								<div class="areaTitle">
									<img src="../static/img/plant.png">
									<div>产业科目种类 （亩）</div>
								</div>
								<div class="areaMain">
									<div class="areaMainLeft">
										<div class="name">总面积</div>
										<div class="area">{{totalArea}}亩</div>
									</div>
									<div class="areaMainRight">
										<div class="areaMainRightItem" v-for="item of townshipInfoList">
											<div class="areaItem">{{item.type}}</div>
											<div class="areaItem">{{item.num}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="footer_item"></div>
					</div>
					<!--  -->
					<div class="topright">
						<!-- 新型农业主体 -->
						<div class="topright_top item">
							<BarLine :lineData="yearLineData" :xData="yearXData"></BarLine>
							<div class="footer_item"></div>
						</div>
						<!--农业金融  -->
						<div class="topright_bottom item">
							<div class="title">乡村产业金融服务</div>
							<ScrollInfo :config="config4"></ScrollInfo>
							<div class="footer_item"></div>
						</div>
					</div>
				</div>
				<!-- 下部分 -->
				<div class="bottomHome">
					<!-- 采购商订单 -->
					<div class="bottomleft item">
						<div class="title">全国农品市场采购</div>
						<ScrollInfo :config="config1"></ScrollInfo>
						<div class="footer_item"></div>
					</div>
					<!-- 农产品产地价格曲线图 -->
					<div class="bottomcenter item">
						<LineChart :xData="xData" :yData="yData" :dateData="dateData"></LineChart>
						<div class="footer_item"></div>
					</div>
					<!--农户供应信息  -->
					<div class="bottomright item">
						<div class="title">全镇农品供应信息</div>
						<ScrollInfo :config="config2"></ScrollInfo>
						<div class="footer_item"></div>
					</div>
				</div>
			</div>
		</div>
	</dv-full-screen-container>
</template>
<script>
	import countTo from 'vue-count-to';
	export default {
		name: 'home',
		data() {
			return {
				// 天气
				weatherInfo: [],
				// 主体 人口 种植户
				townshipInfo: {},
				// 总面积
				totalArea: 0,
				// 种类列表
				townshipInfoList: [],
				// 农作物种类占比
				scaleData: [],
				// 柱形图
				yearXData: [],
				yearLineData: [],
				// 采购
				config1: {},
				// 供应
				config2: {},
				// 农业金融
				config4: {},
				// 折线图数据
				dateData: [],
				yData: [],
				xData: []
			};
		},
		components: {
			countTo
		},
		created() {},
		mounted() {
			this.getDataInfo()
		},
		methods: {
			getDataInfo() {
				// 天气
				this.$api.getWether().then(res => {
					this.weatherInfo = res.data ? res.data.slice(0, 5) : []
				}).catch(err => {
					console.log(err);
				})
				// 全景图
				this.$api.townshipData().then(res => {
					if (res.code === 200) {
						this.townshipInfo = res.data[0]
					} else {}
				}).catch(res => {});
				// 种类
				this.$api.townshipLand().then(res => {
					if (res.code === 200) {
						this.townshipInfoList = res.data
						res.data ? res.data.forEach((val, index) => {
							this.totalArea += val.num
						}) : ''
					} else {}
				}).catch(res => {});
				// 环形图
				this.$api.townshipDataHuan().then(res => {
					res.type ? res.type.forEach((val, index) => {
						this.scaleData.push({
							name: val,
							value: res.per[index].split('%')[0]
						})
					}) : ''
				}).catch(res => {});
				//  柱形
				this.$api.townshipAgriculture().then(res => {
					this.yearLineData = res.per
					this.yearXData = res.village
				}).catch(res => {});
				// 农业金融
				this.$api.townshipFinance().then(res => {
					let configData = []
					res.data ? res.data.forEach((val, index) => {
						configData.push([val.township, val.lender, val.amount, val.bank])
					}) : ''
					this.config4 = {
						headerBGC: '',
						oddRowBGC: '',
						evenRowBGC: '',
						header: ['产业村', '申请人', '资金需求（元）', '资金用途'],
						align: ["left"],
						rowNum: 5,
						data: configData

					}
				}).catch(res => {});
				// 采购商订单
				this.$api.getAgricultureSupplyData().then(res => {
					if (res.code === 200) {
						console.log(res.data)
						let configData = []
						res.data ? res.data.forEach((val, index) => {
							configData.push([val.area, val.product, val.info])
						}) : ''
						this.config1 = {
							headerBGC: '',
							oddRowBGC: '',
							evenRowBGC: '',
							header: ['市场来源', '需求产品', '采购信息'],
							align: ["left"],
							rowNum: 5,
							data: configData

						}
					} else {}
				}).catch(res => {});
				// 果农订单
				this.$api.getFruitData().then(res => {
					if (res.code === 200) {
						let configData = []
						res.data ? res.data.forEach((val, index) => {
							configData.push([val.area, val.product, val.info])
						}) : ''
						this.config2 = {
							headerBGC: '',
							oddRowBGC: '',
							evenRowBGC: '',
							header: ['服务站', '供应产品', '备注信息'],
							align: ["left"],
							rowNum: 5,
							data: configData

						}
					} else {}
				}).catch(res => {});
				// 折线图
				this.$api.marketPriceData().then(res => {
					this.dateData = res.date
					this.xData = ['韭菜', '辣椒', '茄子', '玉米', '青瓜', '木瓜']
					this.yData = [{
							name: '韭菜',
							type: 'line',
							data: res.jc
						},
						{
							name: '辣椒',
							type: 'line',
							data: res.lj
						},
						{
							name: '茄子',
							type: 'line',
							data: res.qz
						},
						{
							name: '玉米',
							type: 'line',
							data: res.ym
						},
						{
							name: '青瓜',
							type: 'line',
							data: res.qg
						},
						{
							name: '木瓜',
							type: 'line',
							data: res.mg
						}
					]
				}).catch(res => {});
			}
		}
	};
</script>
<style scoped lang="scss">
	.home {
		width: 100%;
		height: 100%;
		background: url(../static/img/bg.png) no-repeat center center;
		background-size: 100% 100%;
		color: #00F1FF;
		padding-bottom: 20px;
		box-sizing: border-box;
		font-size: 16px;
		// header
		.header {
			height: 100px;
			box-sizing: border-box;
			header {
				width: 100%;
				height: 96px;
				text-align: center;
				font-size: 40px;
				line-height: 70px;
				color: #7efafc;
				background-size: 100% 100%;
				position: relative;
			}
		}
		// 内容部分
		.mainCenter {
			width: 100%;
			// 减去头部
			height: calc(100% - 100px);
			// 新加
			padding: 20px;
			box-sizing: border-box;
			.topcenter {
				.headerNan {
					position: absolute;
					left: 20px;
					font-size: 24px;
					font-weight: bold;
				}
			}
			.topleft,
			.topcenter,
			.bottomleft,
			.bottomcenter {
				margin-right: 1%;
			}
			.topleft_top,
			.topright_top {
				margin-bottom: 20px;
			}
			.item {
				width: 100%;
				padding: 10px 0px;
				border: 1px solid rgba(25, 186, 139, 0.17);
				box-sizing: border-box;
				position: relative;
				.footer_item {
					width: 100%;
					position: absolute;
					left: 0;
					bottom: 0;
					&:after {
						position: absolute;
						bottom: 0;
						right: 0;
						content: "";
						width: 10px;
						height: 10px;
						border-bottom: 2px solid #00E4FF;
						border-right: 2px solid #00E4FF;
					}
					&:before {
						position: absolute;
						bottom: 0;
						left: 0;
						content: "";
						width: 10px;
						height: 10px;
						border-bottom: 2px solid #00E4FF;
						border-left: 2px solid #00E4FF;
					}
				}
				&:before {
					position: absolute;
					top: 0;
					left: 0;
					content: "";
					width: 10px;
					height: 10px;
					border-top: 2px solid #00E4FF;
					border-left: 2px solid #00E4FF;
				}
				&:after {
					position: absolute;
					top: 0;
					right: 0;
					content: "";
					width: 10px;
					height: 10px;
					border-top: 2px solid #00E4FF;
					border-right: 2px solid #00E4FF;
				}
				// 动态滚动部分样式
				.title {
					font-size: 16px;
					font-weight: bold;
					width: 100%;
					text-align: center;
					position: absolute;
					top: 0;
					height: 40px;
					line-height: 40px;
				}
				.dv-scroll-board {
					position: absolute;
					top: 40px;
					width: 100%;
					height: calc(100% - 40px);
				}
				// 计数器
				.databoxinfo {
					position: absolute;
					bottom: 20px;
					right: 20px;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					.dv-border-box-13 {
						width: 160px;
						height: 80px;
						margin-top: 20px;
						.dv-border-box {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							flex-direction: column;
							color: #fff;
							.count {
								color: #00F1FF;
								font-size: 18px;
								font-weight: bold;
								margin-top: 5px;
							}
						}
					}
					// 耕种种类
					.areaBox {
						margin-top: 20px;
						background: rgb(0 149 255 / 17%);
						padding: 10px;
						width: 350px;
						color: #fff;
						box-sizing: border-box;
						.areaTitle {
							display: flex;
							align-items: center;
							font-size: 20px;
							font-weight: bold;
							margin-bottom: 20px;
							img {
								width: 32px;
								height: 32px;
								margin-right: 5px;
							}
						}
						.areaMain {
							display: flex;
							align-items: center;
							.areaMainLeft {
								flex: 1;
								justify-content: center;
								flex-direction: column;
								align-items: center;
								.name,
								.area {
									font-size: 18px;
									text-align: center;
								}
								.area {
									font-size: 22px;
									color: red;
									font-weight: bold;
									margin-top: 10px;
								}
							}
							.areaMainRight {
								display: flex;
								width: 200px;
								flex: 0 0 200px;
								flex-wrap: wrap;
								.areaMainRightItem {
									width: 50%;
									display: flex;
									font-size: 14px;
									.areaItem {
										background: rgb(0 196 255 / 24%);
										flex: 1;
										margin-right: 2px;
										margin-bottom: 2px;
										text-align: center;
									}
								}
							}
						}
					}
				}
			}
			// 天气
			.topleft_top {
				padding: 50px 0 20px !important;
			}
			.topHome {
				height: 67%;
				display: flex;
				// 新加
				margin-bottom: 20px;
				.topleft_top,
				.topleft_bottom,
				.topright_top,
				.topright_bottom {
					height: calc(50% - 10px);
				}
			}
			.bottomHome {
				display: flex;
				height: 33%;
			}
			.topleft,
			.topright,
			.bottomleft,
			.bottomright {
				width: 28%;
				height: 100%;
			}
			.topcenter,
			.bottomcenter {
				width: 42%;
				height: 100%;
			}
		}
	}
</style>
<style>
	.ceil {
		font-size: 16px;
	}
	.bottomleft .ceil,
	.bottomleft .header-item,
	.bottomright .ceil,
	.bottomright .header-item,
	.topright_bottom .ceil,
	.topright_bottom .header-item {
		text-align: center !important;
	}
	.bottomleft .header-item,
	.bottomright .header-item,
	.topright_bottom .header-item {
		color: aqua !important;
	}
	.bottomleft .header,.bottomright .header{
		display: flex;
	}
	.bottomleft .header-item:nth-of-type(1),.bottomleft .header-item:nth-of-type(2),
	.bottomright .header-item:nth-of-type(1),.bottomright .header-item:nth-of-type(2){
		flex: 1;
	}
	.bottomleft .header-item:nth-of-type(3),
	.bottomright .header-item:nth-of-type(3){
		flex:2;
	}
	
	.bottomleft  .row-item .ceil:nth-of-type(1),.bottomleft .row-item .ceil:nth-of-type(2),
	.bottomright .row-item .ceil:nth-of-type(1),.bottomright .row-item .ceil:nth-of-type(2){
		flex: 1;
	}
	.bottomleft .row-item .ceil:nth-of-type(3),
	.bottomright .row-item .ceil:nth-of-type(3){
		flex:2;
	}
	
	
</style>
